import { useState, useCallback } from "react";
import { ModalExternalCheckout } from "../modals/ModalExternalCheckout";


const useExternalCheckoutModal = () => {

    const [showModal, setShowModal] = useState(false);
    
    const openCheckoutModal = useCallback(() => {
        console.log('Opening checkout modal...');
        setShowModal(true);
    }, []);

    const closeCheckoutModal = useCallback(() => {
        setShowModal(false);
    }, []);
    
    const modalExternalCheckoutComponent = <>
        <ModalExternalCheckout showModal={showModal} onHide={closeCheckoutModal} />
    </>

    return { openCheckoutModal, modalExternalCheckoutComponent };
};

export default useExternalCheckoutModal;
