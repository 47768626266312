
import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { AppNavbar } from "../general/AppNavbar";

function CancelCheckout() {

    const [timer, setTimer] = useState(100);
    const secondsToRedirect = 20;

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prev => {
                if (prev <= 0) {
                    clearInterval(interval);
                    window.location.href = '/';
                    return 0;
                }
                return prev - (100 / secondsToRedirect); // Reducir progresivamente en 6 segundos
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const goBackToHome = () => {
        window.location.href = '/';
    }

    return (
        <div>
            <AppNavbar showContextSelector={false} showProfile={false}/>
            <div className="container pt-5">
                <div className="d-flex flex-column align-items-center justify-content-center gap-3 py-5">
                    <h2>¡Tu compra ha sido cancelada!</h2>
                    <p>Gracias por el interés que has mostrado en ViTAG. Esperamos volver a tenerte en contacto pronto.</p>
                    <button onClick={goBackToHome} className="btn btn-vitag mt-3">Ir a la página de inicio</button>
                    <div className="w-50">
                        <ProgressBar animated now={timer} variant="danger" style={{ height: '10px' }} />
                        <p className="text-center mt-2">Se redirigirá automáticamente al inicio en {secondsToRedirect} segundos...</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CancelCheckout;